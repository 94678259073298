import React, {Fragment, useEffect, useReducer, useState} from "react";
import TopBar from "../components/nav/TopBar";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "../components/ui/Button";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "../components/ui/Card";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "../components/ui/Form";
import {Input} from "../components/ui/Input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../components/ui/Select";
import {categories, provinces} from "../lib/constant";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {auth, db} from "../firebase";
import toast from "react-hot-toast";
import {onValue, ref} from "firebase/database";

const formSchema = z.object({
    firstName: z.string().min(1, {
        message: "First name is required"
    }),
    surname: z.string().min(1, {
        message: "Surname is required"
    }),
    email: z.string().min(1, {
        message: "Email address is required"
    }),
    phoneNumber: z.string().min(1, {
        message: "Phone number is required"
    })
})

export const accountRole = [
    {
        label: "Renter",
        value: "renter"
    },
    {
        label: "Salon Owner",
        value: "salon-owner"
    }
]

function ProfilePage() {
    const navigate = useNavigate()
    const [profile, setProfile] = useState({})

    useEffect(() => {
        onValue(ref(db, `/account/${auth?.currentUser?.uid}`), snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                setProfile({ ...data })
            }
        })
    }, [auth?.currentUser?.uid]);

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            firstName: profile.firstName || "",
            surname: profile.surname || "",
            email: profile.email || "",
            phoneNumber: profile.phoneNumber || "",
        }
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        try {
            // db.ref('salons').child(`/${salonId}`).update({
            //     street: values.street,
            //     suburb: values.suburb,
            //     town: values.town,
            //     province: values.province,
            //     postalCode: values.postalCode
            // }).then(() => {
            //     toast.success("Salon location updated")
            //     toggleEdit()
            //     // navigate(0)
            // }).catch((err) => {
            //     toast.error(err.message)
            // })
            // await axios.patch(`/api/courses/${salonId}`, values)
            // router.refresh()

        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <div className="bg-gray-100 w-full h-screen p-6 space-y-6">
            <TopBar title="Profile"/>
            <Card className="pt-3">
                <CardHeader className="settings-form">
                    <h4 className="text-primary">Account Setting</h4>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                            <div className="space-y-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <FormField control={form.control} name="firstName" render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>First Name</FormLabel>
                                            <FormControl>
                                                <Input disabled={isSubmitting} value={profile.firstName} {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )} />
                                    <FormField control={form.control} name="surname" render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Surname</FormLabel>
                                            <FormControl>
                                                <Input disabled={isSubmitting} value={profile.surname} {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )} />
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <FormField control={form.control} name="email" render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Email Address</FormLabel>
                                            <FormControl>
                                                <Input disabled={isSubmitting} value={profile.email} {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )} />
                                    <FormField control={form.control} name="phoneNumber" render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Phone Number</FormLabel>
                                            <FormControl>
                                                <Input disabled={isSubmitting} value={profile.phoneNumber} {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )} />
                                </div>

                            </div>
                            <div className="flex items-center gap-x-2">
                                <Button disabled={!isValid || isSubmitting} type="submit" className="bg-green-500">
                                    Update Profile
                                </Button>
                            </div>
                        </form>
                    </Form>
                </CardHeader>
            </Card>
            <Card className="mb-4">
                <CardHeader>
                    <div>
                        <h4 className="text-primary">Account Role</h4>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                                <FormField control={form.control} name="accountRole" render={({field}) => (
                                    <FormItem>
                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select account role" {...field} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {accountRole.map((category) => (
                                                    <SelectItem value={category.value} className="cursor-pointer">
                                                        {category.label}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <FormMessage/>
                                    </FormItem>
                                )}/>
                                <div className="flex items-center gap-x-2">
                                    <Button disabled={!isValid || isSubmitting} type="submit" className="bg-green-500">
                                        Update
                                    </Button>
                                </div>
                            </form>
                        </Form>
                    </div>
                </CardHeader>
            </Card>
        </div>
    )
}

export default ProfilePage