import React from "react";
import customer from "../media/images/download.jfif"

function Customer() {
    return (
        <div className="grid grid-cols-6 flex-wrap border-b mx-0 pt-4 px-2 items-center">
            <div className="mb-sm-4 mb-3 items-center flex col-span-2">
                <img
                    className="sm:me-4 me-3 h-auto max-w-full rounded"
                    width={90}
                    src={customer}
                    alt="DexignZone"
                />
                <div className="flex-1">
                    <span className="text-[#3b4cb8] block text-sm">#BARBER</span>
                    <h4 className="mb-1 font-semibold text-lg">
                        Robert Patilson
                    </h4>
                    <span className="block mb-0 text-gray-900 text-xs font-light">
                      Rented on 02/05/2020, 12:42 AM
                    </span>
                </div>
            </div>
            <div className="flex flex-col mx-4 mb-3 col-span-2">
                <small className="mb-2 block text-gray-500">Location</small>
                <span className="text-black font-semibold text-sm">
                    TY35 Avenue, GG, London Center
                </span>
            </div>
            <div className="flex flex-col mb-3">
                <small className="mb-2 block text-gray-500">Phone Number</small>
                <span className="text-black font-semibold text-sm">+51 1234 5678</span>
            </div>
            <div className="flex flex-col mb-3">
                <small className="mb-2 block text-gray-500">Email Address</small>
                <span className="text-black font-semibold text-sm">info@example.com</span>
            </div>
        </div>
    )
}

export default Customer