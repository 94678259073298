import React, {useEffect, useState} from "react";
import DataTable from "../components/DataTable";
import {columns} from "../components/Columns";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "../components/ui/Card";
import {Link, useParams} from "react-router-dom";
import {Button} from "../components/ui/Button";
import {LogOut, UserCircle} from "lucide-react";
import {auth, db} from "../firebase";
import {onValue, ref} from "firebase/database";
import logo from "../media/assets/logo1.jfif"
import {SEO} from "../seo/SEO";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/Tabs";
import {Label} from "../components/ui/Label";
import {Input} from "../components/ui/Input";
import {myListings} from "../lib/constant";

function MySalonPage() {
    const {userId} = useParams()

    const [username, setUsername] = useState("")
    const [salons, setSalons] = useState([])

    useEffect(() => {
        onValue(ref(db, `/account/${userId}`), snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.child("firstName").val()
                setUsername(data)
            }
        })
        onValue(ref(db, `/mySalons/${userId}`), snapshot => {
            if (snapshot.exists()) {
                setSalons([])
                const data = snapshot.val()
                if (data !== null) {
                    Object.values(data).map((item) => {
                        setSalons((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [userId]);

    return (
        <div>
            <SEO
                title="My Salon | Add or edit your salon profile"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/account/my-salons'
            />

            <div className="p-6">
                <Tabs defaultValue="all-listings" className="w-full">
                    <TabsList className="grid w-full grid-cols-5">
                        {myListings.map((item) => (
                            <TabsTrigger value={item.value}>{item.title}</TabsTrigger>
                        ))}
                    </TabsList>
                    <TabsContent value="all-listings">
                        <DataTable columns={columns} data={salons}/>
                    </TabsContent>
                    <TabsContent value="published">
                        <DataTable columns={columns} data={salons.filter((search) => search.isPublished === true)}/>
                    </TabsContent>
                    <TabsContent value="draft">
                        <DataTable columns={columns} data={salons.filter((search) => search.isPublished === false)}/>
                    </TabsContent>
                    <TabsContent value="on-hold">
                        <DataTable columns={columns} data={salons.filter((search) => search?.isOnHold === true)}/>
                    </TabsContent>
                    <TabsContent value="de-listed">
                        <DataTable columns={columns} data={salons.filter((search) => search?.isDeListed === true)}/>
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    )
}

export default MySalonPage