import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import salons from "../lib/salons.json"
import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "../components/ui/Carousel";
import {AspectRatio} from "../components/ui/AspectRatio";
import {Button} from "../components/ui/Button";
import {MapPin, ShieldCheck} from "lucide-react";
import {salonRules} from "../lib/constant";
import Salon from "../components/Salon";
import {auth, db} from "../firebase";
import {onValue, ref} from "firebase/database";
import RentChairForm from "../components/form/RentChairForm";
import WriteReviewForm from "../components/form/WriteReviewForm";

function SalonDetailPage() {
    const {salonName} = useParams()

    const [profile, setProfile] = useState({})
    const [isCurrentUser, setIsCurrentUser] = useState("")
    const [open, setOpen] = useState(false)

    useEffect(() => {
        onValue(ref(db, `/account/${auth?.currentUser?.uid}`), snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                setProfile({ ...data })
            }
        })
        const storedUserId = localStorage.getItem('rentChairUserId')
        if (storedUserId) {
            setIsCurrentUser(storedUserId)
        }
        window.scrollTo(0, 0)
    }, [auth?.currentUser?.uid]);

    return (
        <>
            {salons.filter((search) => search.url === salonName).map((salon) => (
                <div>
                    <div className="page-title">
                        <div className="w-layout-blockcontainer container w-container">
                            <div className="sub-title">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                                    loading="lazy" alt="Title Icon"/>
                                <div>Explore Salon</div>
                            </div>
                            <h1 className="room-main-title">{salon.name}</h1>
                        </div>
                        <div className="w-layout-blockcontainer container w-container">
                            <div className="flex mt-8 title-color items-center gap-x-1">
                                <MapPin className="h-4 w-4"/>
                                <p className="mb-0">{salon.city}, {salon.province}</p>
                            </div>
                        </div>
                    </div>
                    <div className="page-data">
                        <section className="room-main">
                            <div className="w-layout-blockcontainer container w-container">
                                <div className="room-wrap">
                                    <div className="room-left">
                                        <Carousel className="w-full mx-auto">
                                            <CarouselContent>
                                                {salon?.images.map((img, index) => (
                                                    <CarouselItem key={index}>
                                                        <AspectRatio ratio={16 / 9} className="room-main-img">
                                                            <img loading="eager"
                                                                 data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                                                                 src={img.image}
                                                                 alt="Room Main Image"
                                                                 sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 70vw"
                                                                 srcSet={`${img.image} 500w, ${img.image} 800w, ${img.image} 1080w, ${img.image} 1800w`}
                                                                 className="room-main-image"
                                                            />
                                                        </AspectRatio>
                                                    </CarouselItem>
                                                ))}
                                            </CarouselContent>
                                            <CarouselPrevious className="ml-16"/>
                                            <CarouselNext className="mr-16"/>
                                        </Carousel>
                                        <div className="room-details w-richtext">
                                            <p>{salon.description}</p>
                                        </div>
                                        <div>
                                            <h3 className="room-heading">Amenities</h3>
                                            <div className="w-dyn-list">
                                                <div role="list" className="amenity-list w-dyn-items">
                                                    {salon?.features.map((item) => (
                                                        <div role="listitem" className="amenity-item w-dyn-item">
                                                            <div className="amenity-block">
                                                                <div className="amenity-info">
                                                                    <div className="amenity-title">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="room-heading">Salon Rules</h3>
                                            <div className="room-rules w-richtext">
                                                <ul role="list">
                                                    {salonRules.map((item) => (
                                                        <div className="flex items-center gap-x-2">
                                                            <ShieldCheck className="h-4 w-4"/>
                                                            <li>{item.title}</li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="room-heading">Which types of professionals are a good fit for
                                                your space?:</h3>
                                            <div className="room-rules w-richtext">
                                                <ul role="list">
                                                    <div className="flex items-center gap-x-2">
                                                        <ShieldCheck className="h-4 w-4"/>
                                                        <li>{salon.category}</li>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room-right">
                                        <div>
                                            <div>
                                                <h5 className="room-sub-title">Information</h5>
                                                <div className="room-information">
                                                    <div className="room-info-block">
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362f53f09871d3569a0_ic-sq.svg"
                                                            loading="lazy" alt="Room Icon"/>
                                                        <div>{salon.status}</div>
                                                    </div>
                                                    <div className="room-info-block">
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362814c672af61e24d8_ic-bed.svg"
                                                            loading="lazy" alt="Room Icon"/>
                                                        <div>{salon.chairs} Chair</div>
                                                    </div>
                                                    <div className="room-info-block">
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d83620edef77fe22b05bb_ic-person.svg"
                                                            loading="lazy" alt="Room Icon"/>
                                                        <div>1-3 Clients</div>
                                                    </div>
                                                    <div className="room-info-block">
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d9144269de6b13340eecb_bathroom.svg"
                                                            loading="lazy" alt="Room Icon"/>
                                                        <div>{salon.spaceType}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="room-price">
                                                <div>Rental:</div>
                                                <div className="price">{salon.rental}</div>
                                            </div>
                                            <div>
                                                <h5 className="room-sub-title">Extra Services</h5>
                                                <div className="es-wrap">
                                                    <div className="extra-service">
                                                        <div>Free Private Parking</div>
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938c26b835330a45ccc4_ic-parking.svg"
                                                            loading="lazy" alt="Extra Service Icon"/>
                                                    </div>
                                                    <div className="extra-service">
                                                        <div>Kids Play Area</div>
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938cd7a7c76f6bb4324f_ic-child.svg"
                                                            loading="lazy" alt="Extra Service Icon"/>
                                                    </div>
                                                    <div className="extra-service">
                                                        <div>Free Early Check in</div>
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938cd81f75be3ca17c71_ic-cehck-in.svg"
                                                            loading="lazy" alt="Extra Service Icon"/>
                                                    </div>
                                                    <div className="extra-service">
                                                        <div>Delicious Meals &amp;Snacks</div>
                                                        <img
                                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938cba91c9808c3b2760_ic.svg"
                                                            loading="lazy" alt="Extra Service Icon"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {(() => {
                                                if (isCurrentUser) {
                                                    return (
                                                        <RentChairForm salon={salon} profile={profile} open={open} setOpen={setOpen} />
                                                    )
                                                } else {
                                                    return (
                                                        <Link to="/log-in">
                                                            <Button type="submit" className="bg-[#f64f02] rounded-full w-full">
                                                                Log In To Rent A Chair
                                                            </Button>
                                                        </Link>
                                                    )
                                                }
                                            })()}
                                        </div>
                                        <WriteReviewForm salon={salon} profile={profile} open={open} setOpen={setOpen} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="rooms">
                            <div className="w-layout-blockcontainer container w-container">
                                <div className="section-title">
                                    <div className="sub-title space">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                                            loading="lazy" alt="Title Icon"/>
                                        <div>Explore</div>
                                    </div>
                                    <h2 className="section-heading">Similar Salons</h2>
                                </div>
                                <div className="w-dyn-list">
                                    <div role="list" className="room-list w-dyn-items">
                                        {salons.filter((search) => search.url !== salonName && search.city === salon.city).map((item, key) => (
                                            <div role="listitem" className="room-item w-dyn-item">
                                                <Salon item={item} key={key}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            ))}
        </>
    )
}

export default SalonDetailPage