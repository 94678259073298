import React, {useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import {cn, formatPrice} from "../lib/utils";
import {Grip, Pencil, Trash} from "lucide-react";
import {Badge} from "./ui/Badge";
import {Button} from "./ui/Button";
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "./ui/AlertDialog";
import {Form, FormControl, FormField, FormItem, FormMessage} from "./ui/Form";
import {Input} from "./ui/Input";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {db} from "../firebase";
import toast from "react-hot-toast";

const formSchema = z.object({
    rental: z.string().min(1, {
        message: "Rental price is required"
    })
})

function RentalList({items, salonId}) {
    const [rentalDuration, setRentalDuration] = useState(items);
    const [open, setOpen] = useState(items);

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: ""
    })

    const {isSubmitting, isValid} = form.formState

    useEffect(() => {
        setRentalDuration(items);
    }, [items]);

    const onSubmit = async (values, postId, type) => {
        try {
            db.ref('salons').child(`/${salonId}/rentalDuration/${postId}`).update({
                rental: values.rental,
                isPublished: true
            }).then(() => {
                toast.success(`${type} rentals updated`)
                form.reset()
                setOpen(false)
            }).catch((err) => {
                toast.error(err.message)
            })
        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <DragDropContext onDragEnd={() => {}}>
            <Droppable droppableId="chapters">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {Object.values(rentalDuration).map((rent, index) => (
                            <Draggable
                                key={rent.postId}
                                draggableId={rent.postId}
                                index={index}
                            >
                                {(provided) => (
                                    <div
                                        className={cn(
                                            "flex items-center gap-x-2 bg-slate-200 border-slate-200 border text-slate-700 rounded-md mb-4 text-sm",
                                            rent?.isPublished && "bg-sky-100 border-sky-200 text-sky-700"
                                        )}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        <div
                                            className={cn(
                                                "px-2 py-3 border-r border-r-slate-200 hover:bg-slate-300 rounded-l-md transition",
                                                rent?.isPublished && "border-r-sky-200 hover:bg-sky-200"
                                            )}
                                            {...provided.dragHandleProps}
                                        >
                                            <Grip
                                                className="h-5 w-5"
                                            />
                                        </div>
                                        {rent.title}
                                        <div className="ml-auto pr-2 flex items-center gap-x-2">
                                            {rent?.isPublished && (
                                                <Badge>
                                                    {formatPrice(rent.rental)}
                                                </Badge>
                                            )}
                                            <Button size="sm" variant="icon" >
                                                <Trash className="h-4 w-4" />
                                            </Button>
                                            <AlertDialog onOpenChange={setOpen}>
                                                <AlertDialogTrigger asChild>
                                                    <Button variant="icon" size="sm">
                                                        <Pencil className="w-4 h-4 hover:opacity-75 transition" />
                                                    </Button>
                                                </AlertDialogTrigger>
                                                <AlertDialogContent>
                                                    <AlertDialogHeader>
                                                        <AlertDialogTitle>Rental Form</AlertDialogTitle>
                                                        <AlertDialogDescription>
                                                            Rental duration: {rent.title}
                                                        </AlertDialogDescription>
                                                    </AlertDialogHeader>
                                                    <AlertDialogDescription>
                                                        <Form {...form}>
                                                            <form onSubmit={form.handleSubmit((e) => {onSubmit(e, rent.postId, rent.title)})} className="space-y-4 mt-4">
                                                                <FormField control={form.control} name="rental" render={({ field }) => (
                                                                    <FormItem>
                                                                        <FormControl>
                                                                            <div className="flex items-center">
                                                                                <div className="flex h-[2.6rem] rounded-l-md border border-input bg-black text-white px-3 py-2">
                                                                                    ZAR
                                                                                </div>
                                                                                <Input disabled={isSubmitting} type="number" {...field} className="rounded-l-none border-l-0" />
                                                                            </div>
                                                                        </FormControl>
                                                                        <FormMessage />
                                                                    </FormItem>
                                                                )} />
                                                                <div className="flex items-center gap-x-2">
                                                                    <Button disabled={!isValid || isSubmitting} type="submit"
                                                                            className="w-full">
                                                                        Save
                                                                    </Button>
                                                                </div>
                                                            </form>
                                                        </Form>
                                                    </AlertDialogDescription>
                                                    <AlertDialogFooter>
                                                        <AlertDialogCancel className="w-full">Cancel</AlertDialogCancel>
                                                    </AlertDialogFooter>
                                                </AlertDialogContent>
                                            </AlertDialog>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default RentalList