import React from "react";
import {Card, CardDescription, CardHeader, CardTitle} from "../ui/Card";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator
} from "../ui/Breadcrumb";
import {Link} from "react-router-dom";

function TopBar({ title }) {
    return (
        <Card>
            <CardHeader style={{ paddingTop: 8, paddingBottom: 0 }}>
                <CardTitle>{title}</CardTitle>
                <CardDescription style={{ marginTop: 0 }}>
                    <Breadcrumb>
                        <BreadcrumbList style={{ paddingLeft: 0, marginTop: 0, marginBottom: 0 }}>
                            <BreadcrumbItem>
                                <BreadcrumbLink>
                                    <Link to="/">Home</Link>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbPage>{title}</BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </CardDescription>
            </CardHeader>
        </Card>
    )
}

export default TopBar