import React, {useEffect, useState} from "react";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "../components/ui/Card";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList, BreadcrumbPage,
    BreadcrumbSeparator
} from "../components/ui/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import InfoCard from "../components/InfoCard";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Progress} from "../components/ui/Progress";
import RedialBar from "../components/charts/RadialBar";
import {Star} from "lucide-react";
import PropertySlider from "../components/slider/PropertySlider";
import {Button} from "../components/ui/Button";

import customer from "../media/images/download.jfif"
import customer1 from "../media/images/download-1.jfif"
import TopBar from "../components/nav/TopBar";
import {onValue, ref} from "firebase/database";
import {auth, db} from "../firebase";
import {formatPrice} from "../lib/utils";

const ChartTimeLine = loadable(() =>
    pMinDelay(import("../components/charts/ChartTimeline"), 500)
);

function AnalyticsPage() {
    const {userId} = useParams()

    const [salon, setSalon] = useState({})
    const [profileVisits, setProfileVisits] = useState(0)
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        onValue(ref(db, `/mySalons/${userId}`), snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val()

                setSalon({ ...data })
            }
        })
        onValue(ref(db, `/statistics/${userId}`), snapshot => {
            if (snapshot.exists()) {
                const visits = snapshot.child("profileVisits").numChildren()

                setProfileVisits(visits)
            }
        })
        onValue(ref(db, `/reviews/${userId}`), snapshot => {
            if (snapshot.exists()) {
                setReviews([])

                const data = snapshot.val()

                Object.values(data).map((item) => {
                    setReviews((oldArray) => [...oldArray, item])
                })
            }
        })
    }, [userId]);

    return (
        <div className="bg-gray-100 w-full h-full p-6 space-y-6">
            <TopBar title="Analytics" />
            <div className="grid grid-cols-2 gap-6 ">
                <InfoCard
                    title="1"
                    subHeader="Salon published"
                    percentage={75}
                    description="Target 2 Chairs to Rent"
                    color="#dbdef3"
                    bgColor="#3C4CB8"
                />
                {salon?.rentalDuration !== undefined && Object.values(salon?.rentalDuration)?.map((item, index) => (
                    <InfoCard
                        key={index}
                        title={formatPrice(item.rental)}
                        subHeader="Salon rental"
                        percentage={95}
                        description={`Target ${formatPrice(item.rental)} / month`}
                        color="#d5f5e6"
                        bgColor="#37d159"
                    />
                ))}
            </div>
            <div>
                <Card>
                    <CardHeader>
                        <CardDescription>Total Revenue</CardDescription>
                        <div className="flex gap-x-2 items-baseline">
                            <CardTitle>ZAR 12,000</CardTitle>
                            <p>last month ZAR 3,000</p>
                        </div>
                    </CardHeader>
                    <ChartTimeLine />
                </Card>
            </div>
            <div className="grid grid-cols-3 gap-6">
                <div className="space-y-6">
                    <Card>
                        <CardHeader className="space-y-8">
                            <div>
                                <div className="flex justify-between">
                                    <CardDescription>Salon Profile Visits</CardDescription>
                                    <CardDescription>{profileVisits}</CardDescription>
                                </div>
                                <Progress value={75} className="h-2.5"/>
                            </div>
                            <div>
                                <div className="flex justify-between">
                                    <CardDescription>Salon Rental Units</CardDescription>
                                    {/*<CardDescription>{salon?.rentalChairs} Chairs</CardDescription>*/}
                                </div>
                                <Progress value={90} className="h-2.5"/>
                            </div>
                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <RedialBar/>
                        </CardHeader>
                        <CardFooter>
                            <div className="w-full flex justify-around mt-3">
                                <span className="text-black font-semibold me-5 flex items-center">
                                    <svg className="me-2" width={20} height={20} viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect width={20} height={20} rx={8} fill="#3B4CB8"/>
                                    </svg>
                                    <CardDescription className="mb-0">Usage</CardDescription>
                                </span>
                                <span className="text-black font-semibold flex items-center">
                                    <svg className="me-2" width={20} height={20} viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect width={20} height={20} rx={8} fill="#B655E4"/>
                                    </svg>
                                    <CardDescription className="mb-0">Insight</CardDescription>
                                </span>
                            </div>
                        </CardFooter>
                    </Card>
                </div>
                <div className="col-span-2">
                    <div className="grid grid-cols-2 gap-x-6">
                        <Card>
                            <CardHeader>
                                <CardTitle>Client Reviews</CardTitle>
                            </CardHeader>
                            <CardContent>
                                {reviews?.length > 0 && reviews.map((item, index) => (
                                    <div className="pb-3 mb-3 border-b" key={index}>
                                        <div className="flex mb-3 flex-wrap items-end">
                                            <img className="rounded me-3" src={customer} width={58} alt=""/>
                                            <div>
                                                <p className="">John Doe</p>
                                                <div className="flex">
                                                    <Star className="h-4 w-4"/>
                                                </div>
                                            </div>
                                            <span className="fs-14 ms-auto">5m ago</span>
                                        </div>
                                        <CardDescription>
                                            Friendly service Josh, Lunar and everyone at Just Property
                                            in Hastings deserved a big Thank You from us for moving us
                                            from Jakarta to Medan during the lockdown.
                                        </CardDescription>
                                    </div>
                                ))}
                            </CardContent>
                            <CardFooter>
                                <Link to="/review" className="w-full">
                                    <Button variant="outline" className="w-full">
                                        See More Reviews
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle>Recent Salon</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <PropertySlider salon={salon}/>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsPage