import { ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "ZAR"
    }).format(price)
}

export const isTeacher = (userId) => {
    return userId === "Y9BJ9uctEwQ36QWUaWibOtJsdBk2";
}

export const convertFileToUrl = (file) => URL.createObjectURL(file)

export const formatDate = (timestamp) => {
    const dateFromTimestamp = new Date(timestamp); // Convert the timestamp back to a Date object

    // Get day, month, and year
    const day = dateFromTimestamp.getDate();
    const month = dateFromTimestamp.toLocaleString('default', { month: 'long' }); // Get full month name
    const year = dateFromTimestamp.getFullYear();
    const hour = dateFromTimestamp.getHours();
    const minutes = dateFromTimestamp.getMinutes();

    // // Add correct suffix to the day (1st, 2nd, 3rd, 4th, etc.)
    // const daySuffix = (day) => {
    //     if (day > 3 && day < 21) return 'th'; // 4th to 20th
    //     switch (day % 10) {
    //         case 1: return 'st';
    //         case 2: return 'nd';
    //         case 3: return 'rd';
    //         default: return 'th';
    //     }
    // };

    const formatted = `${day} ${month} ${year}, ${hour}:${minutes}`;
    return formatted
}
