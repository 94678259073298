import React from "react";
import {useNavigate} from "react-router-dom";
import {SidebarItem} from "./SidebarItem";
import {ChartColumnDecreasing, Crown, HandCoins, Mail, ShieldPlus, ShoppingCart, Star, UserRound, UsersRound} from "lucide-react";
import {useSelector} from "react-redux";
import {auth} from "../../firebase";
import {Button} from "../ui/Button";
import toast from "react-hot-toast";

export const SidebarRoutes = () => {
    const navigate = useNavigate()
    const businessId = useSelector((state) => state.auth.businessId);

    const navRoutes = [
        {
            icon: ChartColumnDecreasing,
            label: "Analytics",
            href: `/dashboard/${auth?.currentUser?.uid}/analytics`
        },
        {
            icon: Star,
            label: "Reviews",
            href: `/dashboard/${auth?.currentUser?.uid}/reviews`
        },
        {
            icon: Crown,
            label: "My Listings",
            href: `/dashboard/${auth?.currentUser?.uid}/my-listings`
        },
        {
            icon: UsersRound,
            label: "Customers",
            href: `/dashboard/${auth?.currentUser?.uid}/customers`
        },
        {
            icon: ShoppingCart,
            label: "Purchases",
            href: `/account/${businessId}/${auth?.currentUser?.uid}/purchases`
        },
        {
            icon: ShieldPlus,
            label: "Program Settings",
            href: `/account/${businessId}/${auth?.currentUser?.uid}/program-settings`
        },
        {
            icon: Mail,
            label: "Notifications",
            href: `/account/${businessId}/${auth?.currentUser?.uid}/notifications`
        },
        {
            icon: HandCoins,
            label: "Payout",
            href: `/account/${businessId}/${auth?.currentUser?.uid}/payout`
        },
        {
            icon: UserRound,
            label: "Profile",
            href: `/dashboard/${auth?.currentUser?.uid}/profile`
        },
    ]

    const logOut = () => {
        auth.signOut().then(() => {
            navigate("/")
            localStorage.removeItem("rentChairUserId")
            localStorage.removeItem("rentChairUserType")
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    return (
        <div className="flex flex-col w-full h-full">
            {navRoutes.map((item) => (
                <SidebarItem key={item.href} icon={item.icon} label={item.label} href={item.href} />
            ))}
            <Button className="fixed bottom-0 left-0 rounded-none w-56" onClick={logOut}>
                Log Out
            </Button>
        </div>
    )
}