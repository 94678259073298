import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {
    CirclePlus,
    Code,
    LogOut,
    MessageCircleQuestion, Pencil,
    PlusCircle,
    SeparatorVertical,
    Settings,
    SquarePlus
} from "lucide-react";
import {onValue, ref} from "firebase/database";
import userAvatar from "../../media/images/user-icon.jpg"
import {auth, db} from "../../firebase";
import {Button} from "../ui/Button";
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem, NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger
} from "../ui/NavigationMenu";
import {Separator} from "../ui/Seperator";
import {
    AlertDialog, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../ui/AlertDialog";
import SettingsDialog from "../SettingsDialog";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {Input} from "../ui/Input";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {z} from "zod";

const formSchema = z.object({
    salonName: z.string().min(1, {
        message: "Salon name is required"
    })
})

const NavbarRoutes = () => {
    const location = useLocation()
    const [salonName, setSalonName] = useState("")
    const [salonId, setSalonId] = useState("")
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        onValue(ref(db, `/mySalons/${auth?.currentUser?.uid}`), snapshot => {
            if (snapshot.exists()) {
                const salonName = snapshot.child("salonName").val()
                const salonId = snapshot.child("salonId").val()
                setSalonName(salonName)
                setSalonId(salonId)
            }
        })
    }, [auth?.currentUser?.uid]);

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: ""
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        const postId = db.ref().push().key

        try {
            db.ref('salons').child(`/${postId}`).update({
                salonName: values.salonName,
                salonId: postId,
                isPublished: false
            }).then(() => {
                toast.success("Salon updated")
                setOpen(false)
            }).catch((err) => {
                toast.error(err.message)
            })
            db.ref('mySalons').child(`/${auth?.currentUser?.uid}/${postId}`).update({
                salonName: values.salonName,
                salonId: postId,
                isPublished: false
            }).catch((err) => {
                toast.error(err.message)
            })

        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <>
            <div>
                {/*<AlertDialog onOpenChange={setOpen} open={open}>*/}
                {/*    <AlertDialogTrigger asChild>*/}
                {/*        <Button className="gap-x-2" variant="outline">*/}
                {/*            <CirclePlus className="h-4 w-4" />*/}
                {/*            List Your Salon*/}
                {/*        </Button>*/}
                {/*    </AlertDialogTrigger>*/}
                {/*    <AlertDialogContent>*/}
                {/*        <AlertDialogHeader>*/}
                {/*            <AlertDialogTitle>Create a salon name</AlertDialogTitle>*/}
                {/*            <AlertDialogDescription>*/}
                {/*                <Form {...form}>*/}
                {/*                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">*/}
                {/*                        <FormField control={form.control} name="salonName" render={({ field }) => (*/}
                {/*                            <FormItem>*/}
                {/*                                <FormControl>*/}
                {/*                                    <Input disabled={isSubmitting} placeholder="e.g. 'Frank Beauty Salon'" {...field} />*/}
                {/*                                </FormControl>*/}
                {/*                                <FormMessage />*/}
                {/*                            </FormItem>*/}
                {/*                        )} />*/}
                {/*                        <div className="flex items-center gap-x-2">*/}
                {/*                            <Button disabled={!isValid || isSubmitting} type="submit" className="w-full">*/}
                {/*                                Save*/}
                {/*                            </Button>*/}
                {/*                        </div>*/}
                {/*                    </form>*/}
                {/*                </Form>*/}
                {/*            </AlertDialogDescription>*/}
                {/*        </AlertDialogHeader>*/}
                {/*        <AlertDialogFooter>*/}
                {/*            <AlertDialogCancel className="w-full">Cancel</AlertDialogCancel>*/}
                {/*        </AlertDialogFooter>*/}
                {/*    </AlertDialogContent>*/}
                {/*</AlertDialog>*/}
            </div>
            <div className="flex gap-x-2 ml-auto items-center">
                <NavigationMenu>
                    <NavigationMenuList>
                        <NavigationMenuItem className="mb-0">
                            <NavigationMenuTrigger>
                                <div className="flex gap-x-1 items-center">
                                    <Code />
                                    <p className="mb-0">{salonName}</p>
                                </div>
                            </NavigationMenuTrigger>
                            <NavigationMenuContent className="w-full">
                                <div className="w-56 ">
                                    <div className="px-4 w-full border-b">
                                        <ul className="w-full pl-0">
                                            <li className="row-span-3 pl-0">
                                                <NavigationMenuLink asChild>
                                                    <div
                                                        className="flex items-center w-full select-none gap-x-2 rounded-md bg-gradient-to-b from-muted/50 to-muted py-4 no-underline outline-none focus:shadow-md">
                                                        <Code className="h-6 w-6 ml-3"/>
                                                        <div className="font-light text-xs">
                                                            {salonName}
                                                        </div>
                                                    </div>
                                                </NavigationMenuLink>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="w-full p-4">
                                        <Link to={`/register/${salonId}`}>
                                            <Button className="w-full">
                                                <div className="flex gap-x-2 items-center">
                                                    <Pencil className="h-4 w-4"/>
                                                    Edit Salon
                                                </div>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </NavigationMenuContent>
                        </NavigationMenuItem>
                        <Separator orientation="vertical" className="h-7 text-black"/>
                        <NavigationMenuItem>
                            <Button variant="ghost" size="sm">
                                <MessageCircleQuestion className="h-6 w-7 text-gray-500" />
                            </Button>
                        </NavigationMenuItem>
                        <NavigationMenuItem>
                            <AlertDialog>
                                <AlertDialogTrigger>
                                    <Button variant="ghost" size="sm">
                                        <Settings className="h-6 w-7 text-gray-500" />
                                    </Button>
                                </AlertDialogTrigger>
                                <SettingsDialog />
                            </AlertDialog>
                        </NavigationMenuItem>
                        <NavigationMenuItem>
                            <div className="w-10 h-10">
                                <img src={userAvatar} alt="avatar" className="w-full h-full rounded-full"/>
                            </div>
                        </NavigationMenuItem>
                    </NavigationMenuList>
                </NavigationMenu>
            </div>
        </>
    )
}

export default NavbarRoutes