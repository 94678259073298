import "firebase/compat/storage"
import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/analytics"

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAoaBvEKlutjWCTeziM3m7Yy3aNUOjf6CM",
    authDomain: "rent-a-chair-f97d6.firebaseapp.com",
    databaseURL: "https://rent-a-chair-f97d6-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rent-a-chair-f97d6",
    storageBucket: "rent-a-chair-f97d6.appspot.com",
    messagingSenderId: "663381762949",
    appId: "1:663381762949:web:aedf8f3311895783fb9aec",
    measurementId: "G-2TZNQWVWYQ"
})



const auth = firebaseApp.auth()
const db = firebaseApp.database()
const analytics = firebaseApp.analytics()
const storage = firebaseApp.storage()

analytics.setAnalyticsCollectionEnabled(true)

export { auth, db, storage }
