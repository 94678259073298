import React from "react";
import {
    AlertDialogAction,
    AlertDialogCancel, AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle, AlertDialogTrigger
} from "./ui/AlertDialog";
import {Card, CardDescription, CardHeader, CardTitle} from "./ui/Card";
import {Button} from "./ui/Button";
import {Link} from "react-router-dom";

function SettingsDialog() {
    const data = [
        {
            title: "Business Details",
            description: "View and edit your business's details",
            url: "/profile/business-details"
        },
        {
            title: "Users",
            description: "Manage users and their permissions",
            url: "/profile/users"
        },
        {
            title: "Sender Details",
            description: "Add and manage your email sender details",
            url: "/profile/sender-details"
        },
        {
            title: "Payment Integration",
            description: "Set up affiliate payment methods using payment gateway integrations",
            url: "/profile/payment-integrations"
        },
        {
            title: "Brands",
            description: "Manage your brands and their details",
            url: "/profile/brands"
        },
        {
            title: "User Activity Log",
            description: "View and monitor your users' activity",
            url: "/profile/user-activity-log"
        }
    ]

    return (
        <AlertDialogContent className="w-full">
            <AlertDialogHeader className="w-full">
                <AlertDialogTitle>Settings</AlertDialogTitle>
                <AlertDialogDescription>
                    <div className="grid gap-8 grid-cols-2">
                        {data.map((item, key) => (
                            <Link to={item.url} key={key}>
                                <Card className="cursor-pointer text-start">
                                    <CardHeader>
                                        <CardTitle className="text-lg">{item.title}</CardTitle>
                                        <CardDescription>{item.description}</CardDescription>
                                    </CardHeader>
                                </Card>
                            </Link>
                        ))}
                    </div>
                </AlertDialogDescription>
            </AlertDialogHeader>
        </AlertDialogContent>
    )
}

export default SettingsDialog