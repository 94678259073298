import React, {useEffect, useState} from "react";
import TopBar from "../components/nav/TopBar";
import {Card, CardContent} from "../components/ui/Card";
import DataTable from "../components/DataTable";
import {columns, mySalonColumns} from "../components/Columns";
import {onValue, ref} from "firebase/database";
import {auth, db} from "../firebase";

function ClientHomePage() {
    const [rentRequest, setRentRequest] = useState([])

    useEffect(() => {
        onValue(ref(db, `/rentRequest/${auth?.currentUser?.uid}`), snapshot => {
            if (snapshot.exists()) {
                setRentRequest([])
                const data = snapshot.val()

                if (data) {
                    Object.values(data).map((item) => {
                        setRentRequest((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, []);

    return (
        <div className="p-8 space-y-8">
            <TopBar title="My Salons" />
            <Card>
                <CardContent>
                    <DataTable data={rentRequest} columns={mySalonColumns} />
                </CardContent>
            </Card>
        </div>
    )
}

export default ClientHomePage