import React, {useState} from "react";
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../ui/AlertDialog";
import {Button} from "../ui/Button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {Input} from "../ui/Input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/Select";
import {professionalList} from "../../lib/constant";
import {Textarea} from "../ui/Textarea";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {z} from "zod";
import {auth, db} from "../../firebase";

function RentChairForm({ salon, profile, open, setOpen }) {

    const [data, setData] = useState({
        firstName: profile.firstName,
        surname: profile.surname,
        phoneNumber: "",
        email: profile.email,
        profession: "",
        rentalMessage: `Hello, I am interested in renting a chair at ${salon.name}.`
    })

    const handleChange = name => event => {
        const value = name === "profession" ? event : event.target.value
        setData({ ...data, [name]: value })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const postId = db.ref().push().key

        try {
            db.ref("rentRequest").child(`/${auth?.currentUser?.uid}/${postId}`).update({
                fullName: `${data.firstName} ${data.surname}` || `${profile.firstName} ${profile.surname}`,
                phoneNumber: data.phoneNumber,
                email: data.email || profile.email,
                profession: data.profession,
                rentalMessage: data.rentalMessage,
                createdOn: new Date().getTime(),
                salonName: salon.name,
                rentalPrice: salon.rental,
                url: salon.url,
                status: "Pending"
            }).then(() => {
                setOpen(false)
                toast.success("Message sent successfully")
            }).catch((err) => {
                toast.error(err.message)
            })
        } catch(err) {
            toast.error(err.message)
        }
    }

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
                <Button type="submit" className="bg-[#f64f02] rounded-full w-full">
                    Rent A Chair
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Rent a Chair</AlertDialogTitle>
                    <AlertDialogDescription>
                        <div className="flex gap-x-2 pb-4">
                            <img src={salon?.images[0].image} alt="" className="h-16 w-16"/>
                            <div>
                                <h6 className="text-black">{salon.name}</h6>
                                <p>{salon.rental}</p>
                                {/*<p>{profile.firstName}</p>*/}
                            </div>
                        </div>
                        <form onSubmit={onSubmit} className="space-y-4 mt-4">
                            <div className="space-y-4">
                                <div className="flex justify-between gap-x-2">
                                    <input type="text" name="firstName" value={profile.firstName} required onChange={handleChange("firstName")}
                                           className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                    />
                                    <input type="text" name="surname" value={profile.surname} required onChange={handleChange("surname")}
                                           className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                    />
                                </div>
                                <div className="flex justify-between gap-x-2">
                                    <input type="text" name="phoneNumber" placeholder="Enter phone number" required onChange={handleChange("phoneNumber")}
                                           className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                    />
                                    <input type="email" name="email" required value={profile.email} onChange={handleChange("email")}
                                           className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                    />
                                </div>
                                <Select required name="profession" onValueChange={handleChange("profession")}
                                    defaultValue={data.profession}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Choose your profession"/>
                                    </SelectTrigger>
                                    <SelectContent>
                                        {professionalList.map((category) => (
                                            <SelectItem
                                                value={category.value}
                                                className="cursor-pointer">
                                                {category.title}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <textarea value={data.rentalMessage} required onChange={handleChange("rentalMessage")}
                                          className="flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                />
                            </div>
                            <Button type="submit" className="w-full">
                                Send Message
                            </Button>
                        </form>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel className="w-full">
                        Cancel
                    </AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default RentChairForm