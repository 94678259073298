import React, {useEffect, useState} from "react";
import Banner from "../components/Banner";
import {Link, useParams} from "react-router-dom";
import {Button} from "../components/ui/Button";
import {ArrowLeft, CircleDollarSign, File, LayoutDashboard, ListChecks} from "lucide-react";
import {Actions} from "../components/Actions";
import IconBadge from "../components/IconBadge";
import {TitleForm} from "../components/form/TitleForm";
import {DescriptionForm} from "../components/form/DescriptionForm";
import {ContactForm} from "../components/form/ContactForm";
import {LocationForm} from "../components/form/LocationForm";
import {CategoryForm} from "../components/form/CategoryForm";
import {ServicesForm} from "../components/form/ServicesForm";
import {PaymentOptionForm} from "../components/form/PaymentOptionForm";
import {PriceForm} from "../components/form/PriceForm";
import {ImageForm} from "../components/form/ImageForm";
import {onValue, ref} from "firebase/database";
import {auth, db} from "../firebase";
import {SEO} from "../seo/SEO";
import {TestForm} from "../components/form/TestForm";
import {RentalChairsForm} from "../components/form/RentalChairsForm";

function RegisterSalonPage() {
    const {salonId} = useParams()

    const [salon, setSalon] = useState({
        isPublished: false,
        id: "",
        images: [],
        extraServices: [],
        salonName: "",
        description: "",
        category: "",
        price: 0,
        thumbnailImg: "",
        street: "",
        suburb: "",
        town: "",
        province: "",
        postalCode: "",
        phoneNumber: "",
        email: "",
        paymentOption: "",
        rentalDuration: "",
        rentalChairs: 0
    })
    const [open, setOpen] = useState(false)

    useEffect(() => {
        onValue(ref(db, `salons/${salonId}`), snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val()

                if (data) {
                    setSalon({ ...data })
                }
            }
        })
    }, []);

    const requiredFields = [
        salon?.salonName,
        salon?.description,
        salon?.phoneNumber && salon?.email,
        salon?.street && salon?.suburb && salon?.town && salon?.province && salon?.postalCode,
        salon?.images,
        salon?.rentalDuration,
        salon?.category,
        salon?.extraServices,
        salon?.rentalChairs
    ];

    const totalFields = requiredFields.length;
    const completedFields = requiredFields.filter(Boolean).length;

    const completionText = `(${completedFields}/${totalFields})`;

    const isComplete = requiredFields.every(Boolean);

    return (
        <>
            <SEO
                title="Register Salon | Let's get started"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/register/'
            />
            <Banner variant={salon?.isPublished ? "success" : "warning"}
                label={`This salon is ${salon.isPublished ? "published" : "unpublished"}. It will ${salon.isPublished ? "" : "not"} be visible to the clients.`}
            />
            <div className="p-6">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-y-2">
                        <div className="flex items-center gap-x-4">
                            <Link to={`/account/my-salons/${auth?.currentUser?.uid}`}>
                                <Button size="icon" variant="ghost">
                                    <ArrowLeft />
                                </Button>
                            </Link>
                            <h1 className="text-2xl font-medium">
                                Salon setup
                            </h1>
                        </div>
                        <span className="text-sm text-slate-700">
                            Complete all fields {completionText}
                        </span>
                    </div>
                    <Actions
                        disabled={!isComplete}
                        salonId={salonId}
                        isPublished={salon.isPublished}
                        salon={salon}
                    />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-16">
                    <div className="space-y-6">
                        <div>
                            <div className="flex items-center gap-x-2">
                                <IconBadge icon={LayoutDashboard}/>
                                <h2 className="text-xl">
                                    Business Details
                                </h2>
                            </div>
                            <TitleForm
                                initialData={salon}
                                salonId={salonId}
                            />
                            <DescriptionForm
                                initialData={salon}
                                salonId={salonId}
                            />
                            <ContactForm
                                initialData={salon}
                                salonId={salonId}
                            />
                            <LocationForm
                                initialData={salon}
                                salonId={salonId}
                            />
                            <CategoryForm
                                initialData={salon}
                                salonId={salonId}
                            />
                        </div>
                        <div>
                            <div className="flex items-center gap-x-2">
                                <IconBadge icon={CircleDollarSign}/>
                                <h2 className="text-xl">
                                    Rental fees
                                </h2>
                            </div>
                            {/*<PaymentOptionForm*/}
                            {/*    initialData={salon}*/}
                            {/*    salonId={salonId}*/}
                            {/*/>*/}
                            <PriceForm
                                initialData={salon}
                                salonId={salonId}
                                setOpen={setOpen}
                                open={open}
                            />
                        </div>
                    </div>
                    <div className="space-y-6">
                        <div>
                            <div className="flex items-center gap-x-2">
                                <IconBadge icon={ListChecks}/>
                                <h2 className="text-xl">
                                    Extra services
                                </h2>
                            </div>
                            <ServicesForm
                                initialData={salon}
                                salonId={salonId}
                            />
                            <RentalChairsForm
                                initialData={salon}
                                salonId={salonId}
                            />
                        </div>

                        <div>
                            <div className="flex items-center gap-x-2">
                                <IconBadge icon={File}/>
                                <h2 className="text-xl">
                                    Resources & Images
                                </h2>
                            </div>
                            <ImageForm
                                initialData={salon}
                                salonId={salonId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterSalonPage