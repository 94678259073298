import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {cn} from "../../lib/utils";

export const SidebarItem = ({ icon: Icon, label, href }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {businessId} = useParams()

    const isActive = (location.pathname === "/" && href === "/") || location.pathname === href || location.pathname?.startsWith(`${href}`)

    const onClick = () => {
        navigate(href)
    }

    return (
        <button
            onClick={onClick}
            type="button"
            className={cn(
                "flex items-start gap-x-2 text-slate-500 text-sm font-[500] pl-6 transition-all hover:text-slate-600 hover:bg-slate-500/20",
                isActive && "text-slate-700 bg-slate-400/20 hover:bg-slate-400/20 hover:text-slate-700",
            )}
        >
            <div className="flex items-start gap-x-2 py-4">
                <Icon
                    size={22}
                    className={cn(
                        "text-slate-500",
                        isActive && "text-slate-700",
                    )}
                />
                <span className="items-start">
          {label}
        </span>
            </div>
            <div className={cn(
                "ml-auto opacity-0 border-2 border-slate-700 h-full transition-all",
                isActive && "opacity-100",
            )}/>
        </button>
        // <NavLink to={href}>
        //     {({ isActive }) => (
        //         <div className={cn("flex items-center gap-x-2 text-slate-500 text-sm font-[500] pl-6 transition-all hover:text-slate-500 hover:bg-slate-300/20",
        //             isActive && "text-sky-700 bg-sky-200/20 hover:bg-sky-200/20 hover:text-sky-700")}>
        //             <div className="flex items-center gap-x-2 py-4">
        //                 <Icon size={22} className={cn("text-slate-500", isActive && "text-sky-700")} />
        //                  {label}
        //             </div>
        //             <div className={cn("ml-auto opacity-0 border-2 border-sky-700 h-full transition-all", isActive && "opacity-100")}/>
        //         </div>
        //     )}
        // </NavLink>
    )

}