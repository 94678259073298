import './App.css';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import AboutUsPage from "./pages/AboutUsPage";
import SalonPage from "./pages/SalonPage";
import ContactUsPage from "./pages/ContactUsPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import SalonDetailPage from "./pages/SalonDetailPage";
import RegisterSalonPage from "./pages/RegisterSalonPage";
import GetStartedPage from "./pages/GetStartedPage";
import MySalonPage from "./pages/MySalonPage";
import Navbar from "./components/nav/Navbar";
import AnalyticsPage from "./pages/AnalyticsPage";
import Sidebar from "./components/nav/Sidebar";
import CustomersPage from "./pages/CustomersPage";
import ReviewsPage from "./pages/ReviewsPage";
import ProfilePage from "./pages/ProfilePage";
import ClientHomePage from "./pages/ClientHomePage";
import MySalonListPage from "./pages/MySalonListPage";

function App() {
  return (
      <BrowserRouter>
          <LandingPage />
          <RegisterLayout />
          <SalonDashboardLayout />
      </BrowserRouter>
  );
}

const LandingPage = () => {
    const location = useLocation()

    const isRegister = location.pathname.includes("/register")
    const isAccount = location.pathname.includes("/account")
    const isDashboard = location.pathname.includes("/dashboard")
    const hideNav = location.pathname === "/get-started" || isRegister || isAccount || isDashboard

    return (
        <>
            {!hideNav &&
                <div>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/about-us" element={<AboutUsPage/>}/>
                        <Route path="/salons" element={<SalonPage/>}/>
                        <Route path="/contact-us" element={<ContactUsPage/>}/>
                        <Route path="/log-in" element={<LoginPage/>}/>
                        <Route path="/sign-up" element={<SignUpPage/>}/>
                        <Route path="/salon/:salonName" element={<SalonDetailPage/>}/>
                        <Route path="/my-salons/:clientId" element={<ClientHomePage/>}/>
                    </Routes>
                    <Footer/>
                </div>
            }
        </>
    )

}

const RegisterLayout = () => {
    const location = useLocation()

    const isRegister = location.pathname.includes("/register")
    const isAccount = location.pathname.includes("/account")
    const hideNav = location.pathname === "/get-started" || isRegister || isAccount

    return (
        <div>
            {hideNav &&
                <Routes>
                    <Route path="/get-started" element={<GetStartedPage/>}/>
                    <Route path="/register/:salonId" element={<RegisterSalonPage/>}/>
                    <Route path="/account/my-salons/:userId" element={<MySalonListPage />}/>
                </Routes>
            }
        </div>
    )

}

const SalonDashboardLayout = () => {
    const location = useLocation()

    const isRegister = location.pathname.includes("/dashboard")
    const hideNav = location.pathname === "/dashboard" || isRegister

    return (
        <div className="h-full">
            {hideNav &&
                <>
                    <div className="h-[80px] md:pl-56 fixed inset-y-0 w-full z-50">
                        <Navbar/>
                    </div>
                    <div className="hidden md:flex h-full w-56 flex-col fixed inset-y-0 z-50">
                        <Sidebar/>
                    </div>
                    <main className="md:pl-56 h-full pt-[80px]">
                        <Routes>
                            <Route path="/dashboard/:userId/analytics" element={<AnalyticsPage />} />
                            <Route path="/dashboard/:userId/customers" element={<CustomersPage />} />
                            <Route path="/dashboard/:userId/reviews" element={<ReviewsPage />} />
                            <Route path="/dashboard/:userId/my-listings" element={<MySalonPage />} />
                            <Route path="/dashboard/:userId/profile" element={<ProfilePage />} />
                        </Routes>
                    </main>
                </>
            }
        </div>
    )

}

export default App;
