import React, {useState} from "react";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {Input} from "./ui/Input";
import {Link} from "react-router-dom";
import {Button} from "./ui/Button";
import {PlusCircle} from "lucide-react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "./ui/Table";
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "./ui/AlertDialog";
import {Form, FormControl, FormField, FormItem, FormMessage} from "./ui/Form";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {auth, db} from "../firebase";
import toast from "react-hot-toast";

const formSchema = z.object({
    salonName: z.string().min(1, {
        message: "Salon name is required"
    })
})

const DataTable = ({ columns, data }) => {
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([])
    const [open, setOpen] = React.useState(false);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            columnFilters,
        },
    })

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: ""
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        const postId = db.ref().push().key

        try {
            db.ref('salons').child(`/${postId}`).update({
                salonName: values.salonName,
                salonId: postId,
                isPublished: false
            }).then(() => {
                toast.success("Salon updated")
                setOpen(false)
            }).catch((err) => {
                toast.error(err.message)
            })
            db.ref('mySalons').child(`/${auth?.currentUser?.uid}/${postId}`).update({
                salonName: values.salonName,
                salonId: postId,
                isPublished: false
            }).catch((err) => {
                toast.error(err.message)
            })

        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <div>
            <div className="flex items-center py-4 justify-between">
                <Input
                    placeholder="Filter salons..."
                    value={(table.getColumn("salonName")?.getFilterValue()) ?? ""}
                    onChange={(event) =>
                        table.getColumn("salonName")?.setFilterValue(event.target.value)
                    }
                    className="max-w-sm hidden md:block"
                />
                <AlertDialog onOpenChange={setOpen} open={open}>
                    <AlertDialogTrigger asChild>
                        <Button>
                            <PlusCircle className="h-4 w-4 mr-2" />
                            New salon
                        </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Create a salon name</AlertDialogTitle>
                            <AlertDialogDescription>
                                <Form {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                                        <FormField control={form.control} name="salonName" render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input disabled={isSubmitting} placeholder="e.g. 'Frank Beauty Salon'" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )} />
                                        <div className="flex items-center gap-x-2">
                                            <Button disabled={!isValid || isSubmitting} type="submit" className="w-full">
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                </Form>
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel className="w-full">Cancel</AlertDialogCancel>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    Next
                </Button>
            </div>
        </div>
    )

}

export default DataTable