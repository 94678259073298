import React, {useEffect, useState} from "react";
import TopBar from "../components/nav/TopBar";
import {Card, CardContent, CardFooter} from "../components/ui/Card";
import DataTable from "../components/DataTable";
import {customerColumns} from "../components/Columns";
import Customer from "../components/Customer";
import {
    Pagination,
    PaginationContent, PaginationEllipsis,
    PaginationItem,
    PaginationLink, PaginationNext,
    PaginationPrevious
} from "../components/ui/Pagination";
import {onValue, ref} from "firebase/database";
import {auth, db} from "../firebase";

function CustomersPage() {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        onValue(ref(db, `/customers/${auth?.currentUser?.uid}`), snapshot => {
            if (snapshot.exists()) {
                setCustomers([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setCustomers((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [auth?.currentUser?.uid]);

    return (
        <div className="bg-gray-100 w-full h-full p-6 space-y-6">
            <TopBar title="Customers" />
            <Card>
                <CardContent className="p-10">
                    {customers.map((item, index) => (
                        <Customer item={item} key={index} />
                    ))}
                </CardContent>
                <CardFooter>
                    <Pagination>
                        <PaginationContent>
                            <PaginationItem>
                                <PaginationPrevious href="#" className="no-underline" />
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink href="#" className="no-underline">1</PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationEllipsis />
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationNext href="#" className="no-underline" />
                            </PaginationItem>
                        </PaginationContent>
                    </Pagination>

                </CardFooter>
            </Card>
        </div>
    )
}

export default CustomersPage