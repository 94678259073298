import React from "react";
import {Card, CardDescription, CardHeader, CardTitle} from "./ui/Card";
import ChartDoughnut from "./charts/DonutChart";

function InfoCard({ title, subHeader, description, percentage, color, bgColor }) {
    return (
        <Card>
            <div className="w-full flex justify-between items-center">
                <div className="">
                    <CardHeader>
                        <CardTitle>{title}</CardTitle>
                        <CardDescription className="text-black font-medium">{subHeader}</CardDescription>
                        <p className="text-xs font-light">{description}</p>
                    </CardHeader>
                </div>
                <div className="inline-block relative z-10 mr-4">
                    <ChartDoughnut value={percentage} backgroundColor={bgColor} backgroundColor2="rgba(236,236,236,1)"/>
                    <small className={`text-[${bgColor}] items-center flex font-semibold text-sm h-full justify-center left-0 absolute top-0 w-full`}>
                        {percentage}%
                    </small>
                    <span className={`rounded-full absolute h-[3.56rem] left-1/2 top-1/2 w-[3.56rem] -z-10 bg-[${color}] border-[${color}]`} style={{ transform: `translate(-50%, -50%)`}}/>
                </div>
            </div>
        </Card>
    )
}

export default InfoCard