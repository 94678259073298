import React, {useEffect, useState} from "react";
import TopBar from "../components/nav/TopBar";
import customer from "../media/images/download.jfif"
import customer1 from "../media/images/download-1.jfif"
import {Link} from "react-router-dom";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/Tabs";
import {Card, CardContent, CardHeader} from "../components/ui/Card";
import {Star} from "lucide-react";
import {Button} from "../components/ui/Button";
import {onValue, ref} from "firebase/database";
import {auth, db} from "../firebase";

const ReviewCard = (props) =>{
    return(
        <div className="border-b h-auto mb-0 p-[1.56rem] bg-white relative ">
            <div className="grid grid-cols-9 gap-x-4 items-center">
                <img className="me-3 h-auto max-w-full rounded" width={90} src={props.image} alt="DexignZone"/>
                <div className="grid grid-cols-3 gap-x-3 items-center col-span-5">
                    <div className="w-auto">
                        <span className="text-[#3b4cb8] block text-xs">#HAIRDRESSER</span>
                        <h3 className="text-lg text-black font-semibold mb-1">
                            {props.title}
                        </h3>
                        <span className="block text-gray-900 text-xs font-light mb-3">
                            Join on {props.join}, 12:42
                        </span>
                    </div>
                    <div className="text-[#6e6e6e] mb-2 text-xs font-light col-span-2">
                        {props.para === "one" ?
                            <p>
                                Friendly service Josh, Lunar and everyone at Just
                                Property in Hastings deserved a big Thank You from us
                                for moving us from Jakarta to Medan during the lockdown.
                            </p>
                            :
                            <p>
                                Dealing with Syamsudin and Bakri was a joy. I got in touch with Just Property after seeing a couple of properties that caught my eye. Both Syamsudin and Bakri strive to deliver a professional service and surpassed my expectations - they were not only help.
                            </p>
                        }
                    </div>
                </div>
                <div className="flex items-center col-span-3 w-full justify-between">
                    <div className="me-5 xl:text-center text-start xl:ms-3 sm:mb-0 mb-3 ms-0">
                        <span className="bg-[#dbdef3] text-[#3b4cb8] rounded-full p-1 px-2 font-semibold text-xs info-block mb-2 sm:mb-3">
                            EXCELLENT
                        </span>
                        <span className="mt-2 flex">
                            <Star className="text-[#3b4cb8] h-4 w-4" />{" "}
                            <Star className="text-[#3b4cb8] h-4 w-4" />{" "}
                            <Star className="text-[#3b4cb8] h-4 w-4" />{" "}
                            <Star className="text-[#3b4cb8] h-4 w-4" />{" "}
                            <Star className="text-gray-200 h-4 w-4" />
                        </span>
                    </div>
                    <div className="flex gap-x-2 ms-auto">
                        <Button to="/review" className=" me-2" size="sm" variant="outline">
                            Approve
                        </Button>
                        <Button to="/review" className="" variant="destructive" size="sm">
                            Reject
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const reviewtable = [
    { image: customer, join: "26/04/2020", title: 'Robert Patilson', detail: 'one'},
    { image: customer1, join: "20/07/2021", title: 'Peter Parkur', detail: 'two'},
    { image: customer, join: "15/01/2019", title: 'Emilia Sigh', detail: 'one'},
    { image: customer1, join: "10/08/2022", title: 'Robert Patilson', detail: 'one'},
    { image: customer, join: "22/09/2020", title: 'Peter Parkur', detail: 'two'},
    { image: customer1, join: "01/01/2023", title: 'Emilia Sigh', detail: 'one'},
];

function ReviewsPage() {
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        onValue(ref(db, `/reviews/${auth?.currentUser?.uid}`), snapshot => {
            if (snapshot.exists()) {
                setReviews([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setReviews((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [auth?.currentUser?.uid]);

    return (
        <div className="bg-gray-100 w-full h-full p-6 space-y-6">
            <TopBar title="Reviews"/>
            <Card>
                <CardHeader>
                    <Tabs defaultValue="allReviews" className="w-full">
                        <TabsList>
                            <TabsTrigger value="allReviews">All Reviews</TabsTrigger>
                            <TabsTrigger value="published">Published</TabsTrigger>
                            <TabsTrigger value="deleted">Deleted</TabsTrigger>
                        </TabsList>
                        <TabsContent value="allReviews">
                            {reviews.map((item, ind) => (
                                <ReviewCard image={item?.image}
                                            title={item?.title}
                                            join={item?.join}
                                            para={item?.detail}
                                            key={ind}
                                />
                            ))}
                        </TabsContent>
                        <TabsContent value="published">
                            {reviews.filter((search) => search?.isPublished === true).map((item, ind) => (
                                <ReviewCard image={item?.image}
                                            title={item?.title}
                                            join={item?.join}
                                            para={item?.detail}
                                            key={ind}
                                />
                            ))}
                        </TabsContent>
                        <TabsContent value="deleted">
                            {reviews.filter((search) => search?.isPublished === false).map((item, ind) => (
                                <ReviewCard image={item?.image}
                                            title={item?.title}
                                            join={item?.join}
                                            para={item?.detail}
                                            key={ind}
                                />
                            ))}
                        </TabsContent>
                    </Tabs>
                </CardHeader>
            </Card>
        </div>
    )
}

export default ReviewsPage