import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {Button} from "../ui/Button";
import {Pencil} from "lucide-react";
import {z} from "zod"
import {cn} from "../../lib/utils";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {Textarea} from "../ui/Textarea";
import {db} from "../../firebase";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const formSchema = z.object({
    description: z.string().min(1, {
        message: "Description is required"
    })
})

export const DescriptionForm = ({ initialData, salonId }) => {
    const router = useNavigate()

    const [isEditing, setIsEditing] = useState(false)
    const toggleEdit = () => setIsEditing((current) => !current)

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            description: initialData?.description || ""
        }
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        try {
            db.ref('salons').child(`/${salonId}`).update({
                description: values.description
            }).then(() => {
                toast.success("Salon description updated")
                toggleEdit()
                // router.refresh()
            }).catch((err) => {
                toast.error(err.message)
            })
            // await axios.patch(`/api/courses/${salonId}`, values)
        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <div className="mt-6 bg-slate-100 rounded-md p-4">
            <div className="font-medium flex items-center justify-between">
                Business description
                <Button variant="ghost" onClick={toggleEdit}>
                    {isEditing ? (<>Cancel</>) :
                        (
                            <>
                                <Pencil className="h-4 w-4 mr-2" />
                                Edit description
                            </>
                        )
                    }
                </Button>
            </div>
            {!isEditing && (
                <p className={cn("text-sm mt-2", !initialData.description && "text-slate-500 italic")}>
                    {initialData.description || "No description"}
                </p>
            )}
            {isEditing && (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                        <FormField control={form.control} name="description" render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Textarea className="h-auto" disabled={isSubmitting} placeholder="e.g. 'This business is about...'" {...field} />
                                    {/*<CKEditor*/}
                                    {/*    editor={ClassicEditor}*/}
                                    {/*    // data={generalInfo?.description}*/}
                                    {/*    onReady={ editor => {} }*/}
                                    {/*    onChange={ ( event, editor ) => {*/}
                                    {/*        // setGeneralInfo({ ...generalInfo, ["description"]: editor.getData()})*/}
                                    {/*    } }*/}
                                    {/*    onBlur={ ( event, editor ) => {}}*/}
                                    {/*    onFocus={ ( event, editor ) => {} }*/}
                                    {/*    disabled={isSubmitting}*/}
                                    {/*    {...field}*/}
                                    {/*/>*/}
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )} />
                        <div className="flex items-center gap-x-2">
                            <Button disabled={!isValid || isSubmitting} type="submit">
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            )}
        </div>
    )
}