import React from "react";
import {SidebarRoutes} from "./SidebarRoutes";
import logo from "../../media/assets/logo1.jfif"
import {CardDescription, CardHeader, CardTitle} from "../ui/Card";

const Sidebar = () => {
    return (
        <div className="h-full border-r flex flex-col overflow-y-auto bg-white shadow-sm">
            <div className="">
                <CardHeader>
                    <div className="flex gap-x-2 items-center">
                        <img src={logo} alt="" className="h-7 w-7"/>
                        <div className="hidden md:block mt-2">
                            <CardDescription>
                                <p className="font-bold text-sm">Rent-A-Chair</p>
                            </CardDescription>
                            <CardDescription className="-mt-2.5">
                                <p className="text-xs font-light">Short-term salon rental</p>
                            </CardDescription>
                        </div>
                    </div>
                </CardHeader>
            </div>
            <div className="flex flex-col w-full">
                <SidebarRoutes />
            </div>
        </div>
    )
}

export default Sidebar