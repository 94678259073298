import React from "react";
import {AspectRatio} from "./ui/AspectRatio";
import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "./ui/Carousel";
import {Card, CardContent} from "./ui/Card";
import {Link} from "react-router-dom";

function Salon({ item, key }) {
    return (
        <div role="listitem" className="room-item w-dyn-item" key={key}>
            <div data-w-id="a72fb718-089b-2900-c018-37bf2e6c422f" className="room-block w-inline-block">
                <Carousel className="w-full mx-auto">
                    <CarouselContent>
                        {item?.images.map((img, index) => (
                            <CarouselItem key={index}>
                                <AspectRatio ratio={3 / 2} className="room-img">
                                    <img loading="lazy" className="room-image object-cover"
                                         style={{transform: `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                         data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                                         alt="Room Image" src={img.image} sizes="(max-width: 479px) 100vw, (max-width: 767px) 46vw, 47vw"
                                    />
                                </AspectRatio>
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                    <CarouselPrevious className="ml-16" />
                    <CarouselNext className="mr-16" />
                </Carousel>
                <div>
                    <div className="flex justify-between items-center">
                        <div className="room-info">
                            <div className="room-info-block">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362f53f09871d3569a0_ic-sq.svg"
                                    loading="lazy" alt="Room Icon"/>
                                <div>{item.status}</div>
                            </div>
                            <div className="room-info-block">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362814c672af61e24d8_ic-bed.svg"
                                    loading="lazy" alt="Room Icon"/>
                                <div>{item.chairs}</div>
                            </div>
                            <div className="room-info-block">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d83620edef77fe22b05bb_ic-person.svg"
                                    loading="lazy" alt="Room Icon"/>
                                <div>{item.numberOfClients}</div>
                            </div>
                        </div>
                        <p className="mb-0 text-blue-600">{item.rental}</p>
                    </div>
                    <Link to={`/salon/${item.url}`} className="no-underline">
                        <h6 className="room-title font-light">{item.name}</h6>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Salon