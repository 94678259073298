import React, {useEffect, useState} from "react";
import {navLink} from "../lib/constant";
import {Link, useNavigate} from "react-router-dom";
import logo from "../media/assets/logo1.jfif"
import {Button} from "./ui/Button";
import {auth} from "../firebase";
import {ChevronDown, Power} from "lucide-react";
import toast from "react-hot-toast";

function Header() {
    const navigate = useNavigate()

    const [isNavOpen, setIsNavOpen] = useState(false)
    const [isCurrentUser, setIsCurrentUser] = useState("")
    const [isUserType, setIsUserType] = useState("")

    useEffect(() => {
        const storedUserId = localStorage.getItem('rentChairUserId')
        const storedUserType = localStorage.getItem('rentChairUserType')

        if (storedUserType) {
            setIsUserType(storedUserType)
        } else {
            setIsUserType("")
        }

        if (storedUserId) {
            setIsCurrentUser(storedUserId)
        } else {
            setIsCurrentUser("")
        }
    }, []);

    const handleNav = () => {
        if (isNavOpen) {
            setIsNavOpen(false)
        } else {
            setIsNavOpen(true)
        }
    }

    const logOut = () => {
        auth.signOut().then(() => {
            toast("Account successfully logged out")
            localStorage.removeItem("rentChairUserId")
            localStorage.removeItem("rentChairUserType")

            navigate("/")
            navigate(0)
        }).catch((err) => {
            toast(err.message)
        })
    }

    return (
        <section className="header shadow">
            {/*{alert(isCurrentUser)}*/}
            <div data-w-id="4821f45e-eb0d-b0d9-a76c-aebd54349bde" data-animation="default" data-collapse="medium"
                 data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar line w-nav">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="navbar-wrap">
                        <div className="navbar-brand">
                            <a href="/" aria-current="page" className="brand w-inline-block w--current">
                                <img src={logo} height={50} width={50} loading="lazy" alt="Cruise"/>
                            </a>
                        </div>
                        <div className="navbar-left">
                            <nav role="navigation" className="nav-menu w-nav-menu">
                                {navLink.map((item, key) => (
                                    <Link to={item.url} key={key} className="nav-link">
                                        {item.title}
                                    </Link>
                                ))}
                                <Link to="/log-in" className={`${isCurrentUser ? "hidden" : "login-btn mobile"}`}>
                                    <Button className="bg-[#f64f02] w-full rounded-full">Login</Button>
                                </Link>
                                <Link to="/sign-up" className={`${isCurrentUser ? "hidden" : "login-btn mobile"}`}>
                                    <Button variant="outline" className="w-full border-black">
                                        Create an Account
                                    </Button>
                                </Link>
                                <Link to={`${isUserType === "salonOwner" ? `/account/my-salons/${auth?.currentUser?.uid}` : `/my-salons/${auth?.currentUser?.uid}`}`}
                                      className={`${!isCurrentUser ? "hidden" : "login-btn mobile"}`}>
                                    <Button variant="outline" className="w-full border-black gap-x-2">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665968abc64483f660664584_ic-login.svg"
                                            loading="lazy" alt="Login Icon"/>
                                        <div>My Account</div>
                                    </Button>
                                </Link>
                            </nav>
                        </div>
                        <div className="navbar-right">
                            <Link to="/log-in" className={`${isCurrentUser ? "hidden" : "navbar-login w-inline-block"}`}>
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665968abc64483f660664584_ic-login.svg"
                                    loading="lazy" alt="Login Icon"/>
                                <div>Login</div>
                            </Link>
                            <Link to="/sign-up" className={`${isCurrentUser ? "hidden" : "navbar-login w-inline-block"}`}>
                                <Button variant="outline" className="rounded-full uppercase">Create an Account</Button>
                            </Link>
                            <Link to={`${isUserType === "salonOwner" ? `/account/my-salons/${auth?.currentUser?.uid}` : `/my-salons/${auth?.currentUser?.uid}`}`}
                                  className={`${isCurrentUser ? "navbar-login w-inline-block" : "hidden"}`}>
                                <Button variant="outline" className="w-full rounded-full gap-x-2">
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665968abc64483f660664584_ic-login.svg"
                                        loading="lazy" alt="Login Icon"/>
                                    <div>My Account</div>
                                    <ChevronDown />
                                </Button>
                            </Link>
                            <Button variant="icon" onClick={logOut}
                                    className={`${isCurrentUser ? "navbar-login w-inline-block" : "hidden"} w-full rounded-full gap-x-2`}>
                                <Power />
                            </Button>
                            <div className="menu-button w-nav-button" onClick={handleNav}>
                                <div
                                    style={{transform: `translate3d(0, ${isNavOpen ? "7px" : "0px"}, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(${isNavOpen ? "-45deg" : "0deg"}) skew(0, 0)`}}
                                    className="top-line"></div>
                                <div style={{opacity: isNavOpen ? 0 : 1}} className="center-line"></div>
                                <div
                                    style={{transform: `translate3d(0, ${isNavOpen ? "-7px" : "0px"}, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(${isNavOpen ? "45deg" : "0deg"}) skew(0, 0)`}}
                                    className="bottom-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"
                     style={{height: `${isNavOpen ? "1000vh": "0px"}`, display: `${isNavOpen ? "block" : "none"}`}}>
                    <nav role="navigation" className="nav-menu w-nav-menu" data-nav-menu-open=""
                         style={{transform: `translateY(0px) translateX(0px)`, transition: `transform 400ms ease 0s`}}>
                        {navLink.map((item) => (
                            <Link to={item.url} className="nav-link" onClick={handleNav}>{item.title}</Link>
                        ))}
                        <Link to="/log-in" onClick={handleNav} className={`${isCurrentUser ? "hidden" : ""}`}>
                            <Button className="w-full mt-8 rounded-full bg-[#f64f02]">Login</Button>
                        </Link>
                        <Link to="/sign-up" onClick={handleNav} className={`${isCurrentUser ? "hidden" : ""}`}>
                            <Button variant="outline" className="w-full border-black mt-5 rounded-full uppercase">
                                Create an Account
                            </Button>
                        </Link>
                        <Link to={`${isUserType === "salonOwner" ? `/account/my-salons/${auth?.currentUser?.uid}` : `/my-salons/${auth?.currentUser?.uid}`}`}
                              className={`${isCurrentUser ? "mt-4" : "hidden"}`}>
                            <Button variant="outline" className="w-full rounded-full gap-x-2">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665968abc64483f660664584_ic-login.svg"
                                    loading="lazy" alt="Login Icon"/>
                                <div>My Account</div>
                                <ChevronDown />
                            </Button>
                        </Link>
                    </nav>
                </div>
            </div>
        </section>
    )
}

export default Header