import React from "react";
import { Link } from "react-router-dom";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import {  Navigation, HashNavigation } from 'swiper/modules';
import 'swiper/css';
import {ArrowLeft, ArrowRight} from "lucide-react";

import testimonial1 from "../../media/images/salon1.png";
import testimonial2 from "../../media/images/salon2.png";
import {formatPrice} from "../../lib/utils";

const sliderData = [
    {title:'98AB Alexander Court, London', image: testimonial1},
    {title:'2AB Historical landmark, Paris', image: testimonial2},
];

const PropertySlider = ({ salon }) => {

    return (
        <div className="home-slider">
            <div className="items">
                <Swiper loop={true} navigation={{prevEl: ".left-arrow", nextEl: ".right-arrow"}}
                        modules={[Navigation, HashNavigation]} className="mySwiper">
                    <SwiperSlide>
                        {salon?.images !== undefined && Object.values(salon?.images).map((item, index) => (
                            <Link to="#" key={index}>
                                <img src={item.imageUrl} alt="#" className="w-full mw-100 mb-3 rounded"/>
                            </Link>
                        ))}
                    </SwiperSlide>
                </Swiper>
                <h5 className="text-base font-semibold mb-0">
                    <Link to="#" className="text-black no-underline">
                        {salon.salonName}
                    </Link>
                </h5>
                <span className="text-sm block mb-4 capitalize">
                            {salon.street}, {salon.suburb}, {salon.town}, {salon.province}, {salon.postalCode}
                        </span>
                <div className="flex items-baseline justify-between">
                    <Link to="#"
                          className="bgl-primary text-black p-1 px-2 mr-3 font-semibold rounded flex items-center no-underline">
                        <svg className="me-2" width={20} height={13} viewBox="0 0 20 13" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.8125 5.54171C17.6264 5.54171 19.1667 7.01538 19.1667 8.89588V11.7709H17.7292V12.7292H16.7708V11.7709H2.39583V12.7292H1.4375V11.7709H0V8.89588C0 7.08201 1.48814 5.54171 3.35417 5.54171H15.8125ZM14.8542 0.270874C16.1425 0.270874 17.2504 1.30663 17.25 2.66671L17.2504 4.95601C16.8146 4.71707 16.3271 4.58337 15.8125 4.58337L14.8542 4.58289C14.8542 3.76226 14.1822 3.14587 13.4167 3.14587H10.5417C10.1735 3.14587 9.8377 3.28427 9.58338 3.51186C9.32939 3.28444 8.99338 3.14587 8.625 3.14587H5.75C4.95625 3.14587 4.31276 3.78921 4.3125 4.58289L3.35417 4.58337C2.83975 4.58337 2.35239 4.71699 1.91667 4.95579V2.66671C1.91667 1.37835 2.95002 0.270874 4.3125 0.270874H14.8542ZM8.625 4.10397C8.88503 4.10397 9.10417 4.34355 9.10417 4.58337L5.27083 4.58289C5.27083 4.28973 5.50427 4.10421 5.75 4.10421L8.625 4.10397ZM13.4167 4.10743C13.6878 4.10397 13.8956 4.33738 13.8958 4.58289H10.0625C10.0625 4.28554 10.2959 4.10421 10.5417 4.10421L13.4167 4.10743Z"
                                fill="#3B4CB8"
                            />
                        </svg>
                        4
                    </Link>
                    <Link to="#"
                          className="bgl-primary text-black p-1 px-3 font-semibold rounded flex items-center no-underline">
                        <svg className="me-2" width={13} height={15} viewBox="0 0 13 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.92 7.00064L12.6744 8.22859C12.2819 10.1913 10.4996 11.6677 8.55634 11.6677H7.95079L9.11756 14.7791H1.5557V7.00064H12.92ZM3.50032 0C4.54604 0 5.44495 0.871336 5.44495 1.94462V5.05602H12.8345V5.83387H5.44495V6.22279H0.777849L0.777769 7.38965C0.327309 7.05126 0 6.47802 0 5.83387V1.94462C0 0.8989 0.851593 0 1.94462 0H3.50032Z"
                                fill="#3B4CB8"
                            />
                        </svg>
                        {salon?.rentalChairs}
                    </Link>
                    <p>
                        {/*{salon ? formatPrice(Object.values(salon?.rentalDuration)[0]?.rental) : formatPrice(0)}*/}
                    </p>
                </div>
                <p className="text-sm mt-3 mb-0">{salon.description}</p>
            </div>
        </div>
    );
};

export default PropertySlider;
