import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import logo from "../media/assets/logo1.jfif"
import {Card, CardContent, CardDescription} from "../components/ui/Card";
import {Button} from "../components/ui/Button";
import {ArrowLeft, MoveRight, Pencil} from "lucide-react";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../components/ui/Form";
import {Input} from "../components/ui/Input";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {auth, db} from "../firebase";
import toast from "react-hot-toast";
import {z} from "zod";
import {SEO} from "../seo/SEO";

const formSchema = z.object({
    salonName: z.string().min(1, {
        message: "Salon name is required"
    })
})

function GetStartedPage() {
    const navigate = useNavigate()
    const [initialData, setInitialData] = useState("")

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: initialData.salonName
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        const postId = db.ref().push().key

        try {
            db.ref('salons').child(`/${postId}`).update({
                salonName: values.salonName,
                salonId: postId,
                isPublished: false
            }).then(() => {
                toast.success("Salon created successfully")
                navigate(`/register/${postId}`)
            }).catch((err) => {
                toast.error(err.message)
            })
            db.ref('mySalons').child(`/${auth?.currentUser?.uid}/${postId}`).update({
                salonName: values.salonName,
                salonId: postId,
                isPublished: false
            }).catch((err) => {
                toast.error(err.message)
            })

        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <main>
            <SEO
                title="Get Started | Create your salon profile"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/get-started'
            />
            <div>
                <div className="grid grid-cols-1 gap-x-8 lg:grid-cols-2">
                    <section className="w-full h-screen bg-white">
                        <div className="p-8">
                            <div className="mb-4">
                                <Link to="/">
                                    <Button variant="ghost" className="items-center gap-x-2 text-gray-400">
                                        <ArrowLeft className="h-5 w-5" />
                                        <p className="mb-0">Go Back To Home</p>
                                    </Button>
                                </Link>
                            </div>
                            <div className="mb-12">
                                <p className="text-6xl lg:text-7xl">List your salon in 5 minutes</p>
                            </div>
                            <div className="mb-8">
                                <p className="text-lg md:text-xl">Your Rent-A-Chair journey starts with <br/> <span><b>The Salon Name:</b></span>
                                </p>
                            </div>
                            {/*<Card className="w-96 mb-8 bg-gray-50">*/}
                            {/*    <CardContent className="px-8 py-4">*/}
                            {/*        <CardDescription>*/}
                            {/*            <p className="text-lg">Your checklist to list your salon</p>*/}
                            {/*        </CardDescription>*/}
                            {/*        <CardDescription>*/}
                            {/*            <ol>*/}
                            {/*                <li>Create your business profile</li>*/}
                            {/*                <li>Upload pictures and salon description</li>*/}
                            {/*                <li>Set working conditions, rules and rental fees</li>*/}
                            {/*            </ol>*/}
                            {/*        </CardDescription>*/}
                            {/*    </CardContent>*/}
                            {/*</Card>*/}
                            <div className="mt-6 bg-slate-100 rounded-md p-4">
                                <Form {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                                        <FormField control={form.control} name="salonName" render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input disabled={isSubmitting}
                                                           placeholder="e.g. 'Frank Beauty Salon'" {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}/>
                                        <div className="flex items-center gap-x-2">
                                            <Button disabled={!isValid || isSubmitting} type="submit">
                                                List your Salon <span className="ml-2"><MoveRight/></span>
                                            </Button>
                                        </div>
                                    </form>
                                </Form>
                            </div>
                        </div>
                    </section>
                    <section className="w-full hidden lg:block h-screen">
                        <div>
                            <div>
                                <Link to="/">
                                    <img src={logo} alt="logo" className="h-screen w-full"/>
                                </Link>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    )
}

export default GetStartedPage