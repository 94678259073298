import React, {useState} from "react";
import {
    AlertDialog, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../ui/AlertDialog";
import {Button} from "../ui/Button";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/Select";
import {professionalList} from "../../lib/constant";
import {auth, db} from "../../firebase";
import toast from "react-hot-toast";
import {Star} from "lucide-react";
import "../../css/StarRating.css"

function WriteReviewForm({ salon, profile, open, setOpen }) {
    const [data, setData] = useState({
        review: "",
        rating: 0
    })
    const [rating, setRating] = useState(null)
    const [hover, setHover] = useState(null)

    const handleChange = name => event => {
        const value = name === "rating" ? event : event.target.value
        setData({ ...data, [name]: value })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const postId = db.ref().push().key

        try {
            db.ref("reviews").child(`${salon?.salonId}/${auth?.currentUser?.uid}`).update({
                review: data.review,
                createdOn: new Date().getTime(),
                salonName: salon.name,
                rentalPrice: salon.rental,
                url: salon.url
            }).then(() => {
                setOpen(false)
                toast.success("Message sent successfully")
            }).catch((err) => {
                toast.error(err.message)
            })
        } catch(err) {
            toast.error(err.message)
        }
    }

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
                <Button type="submit" variant="outline" className=" rounded-full w-full">
                    Write a Review
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Rent a Chair</AlertDialogTitle>
                    <AlertDialogDescription>
                        <div className="flex gap-x-2 pb-4">
                            <img src={salon?.images[0].image} alt="" className="h-16 w-16"/>
                            <div>
                                <h6 className="text-black">{salon.name}</h6>
                                <p>{salon.rental}</p>
                            </div>
                        </div>
                        <form onSubmit={onSubmit} className="space-y-4 mt-4">
                            <div className="space-y-8">
                                <div>
                                    <label htmlFor="review">Message</label>
                                    <textarea value={data.review} required
                                              onChange={handleChange("review")}
                                              className="flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="rating">Rating</label>
                                    <div className="star-rating">
                                        {[...Array(5)].map((star, index) => {
                                            const starValue = index + 1
                                            return (
                                                <label htmlFor="">
                                                    <input
                                                        type="radio"
                                                        name="rating"
                                                        value={starValue}
                                                        onClick={() => setRating(starValue)}
                                                    />
                                                    <Star
                                                        className="star"
                                                        color={starValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                                        onMouseEnter={() => setHover(starValue)}
                                                        onMouseLeave={() => setHover(null)}
                                                    />
                                                </label>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <Button type="submit" className="w-full">
                                Send Message
                            </Button>
                        </form>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel className="w-full">
                        Cancel
                    </AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default WriteReviewForm